const h = str => {
  let hash = 5381,
      i = str.length;

  while (i) {
    hash = hash * 33 ^ str.charCodeAt(--i);
  }

  return (hash >>> 0) + 2147483647 + 1;
};

const createHash = (str, timestamp) => {
  const ts = timestamp || Date.now();
  return h('' + str + ts) + "-" + ts;
};

const verifyHash = (str, hash) => {
  const ts = hash.split('-')[1];
  return createHash(str, ts) === hash;
};

const getUniquePostPayloadString = payload => {
  return "" + payload.categoryId + payload.title + (payload.content ? payload.content.blocks.map(b => b.key).join('') : '');
};

const tokenName = 'h' + 's' + 'T' + 'o' + 'k' + 'e' + 'n';
const s = '450s8i19';
module.exports = {
  createHash,
  verifyHash,
  getUniquePostPayloadString,
  tokenName,
  s
};