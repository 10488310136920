module.exports = {
  APP_HOST: process.env.APP_HOST || 'localhost',
  APP_PROTOCOL: process.env.APP_PROTOCOL || 'https',
  BLOG_APP_SECRET: process.env.BLOG_APP_SECRET || 'blog-secret',
  BLOG_SECTION_ID: process.env.BLOG_SECTION_ID || 'blog',
  // componentId in dev center
  BLOG_POST_PAGE_SECTION_ID: process.env.BLOG_POST_PAGE_SECTION_ID || 'post',
  BLOG_APP_DEBUG: process.env.BLOG_APP_DEBUG === 'true',
  FORUM_APP_ID: process.env.FORUM_APP_ID || '1489f5ab-9519-19db-aa46-c1cc4ebb3092',
  FORUM_APP_SECRET: process.env.FORUM_APP_SECRET || '8686c5e9-8441-407c-b861-9e1094a49c11',
  FORUM_SECTION_ID: process.env.FORUM_SECTION_ID || 'forum',
  // componentId in dev center
  FORUM_RECENT_POST_WIDGET_ID: '1516e39e-e1a7-6a24-43fc-0e3051c14803',
  FORUM_APP_PAGE_ID: '148a55d1-f383-ce33-574c-a482940b05f4',
  MEMBERS_APP_ID: process.env.MEMBERS_APP_ID || '14b45872-7ce1-9b9e-15cc-f7a6a7307632',
  MEMBERS_APP_SECRET: process.env.MEMBERS_APP_SECRET || '04ddcd6b-f0c0-4773-8a90-e1ae184d0fc9',
  SANTA_MEMBERS_APP_ID: process.env.SANTA_MEMBERS_APP_ID || '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
  MEMBER_INFO_APP_ID: process.env.SANTA_MEMBERS_APP_ID || '14cffd81-5215-0a7f-22f8-074b0e2401fb',
  // we use this to check if member area is installed.
  MEMBER_INFO_SECTION_ID: process.env.MEMBER_INFO_SECTION_ID || 'member_info',
  // we use this to check if member area is installed.
  NOTIFICATIONS_APP_ID: process.env.NOTIFICATIONS_APP_ID || '14f25d9b-33f4-ec0a-9568-f9c5ed98c9b1',
  NOTIFICATIONS_SETTING_APP_ID: process.env.NOTIFICATIONS_SETTING_APP_ID || '14f25b72-9c67-0bce-9568-f9c5ed98c9b1',
  FOLLOWERS_APP_ID: process.env.FOLLOWERS_APP_ID || '14ebe801-d78a-daa9-c9e5-0286a891e46f',
  BUSINESS_MANAGER_BLOG_BASE_URL: process.env.BUSINESS_MANAGER_BLOG_BASE_URL || '',
  BUSINESS_MANAGER_SITE_MEMBER_ID: process.env.BUSINESS_MANAGER_SITE_MEMBER_ID || '',
  BUSINESS_MANAGER_METASITE_ID: process.env.BUSINESS_MANAGER_METASITE_ID || '',
  BUSINESS_MANAGER_DEVELOPMENT_INSTANCE: process.env.BUSINESS_MANAGER_DEVELOPMENT_INSTANCE || '',
  CONTENT_MIGRATION_DEV_SERVER: process.env.CONTENT_MIGRATION_DEV_SERVER === 'true',
  CONTENT_MIGRATION_COOKIE: process.env.CONTENT_MIGRATION_COOKIE || '',
  LOG_REDUX_ACTIONS: process.env.LOG_REDUX_ACTIONS === 'true',
  LOG_BI_TO_CONSOLE: process.env.LOG_BI_TO_CONSOLE === 'true',
  LOG_BLOG_POST_EDITOR_ACTIONS: process.env.LOG_BLOG_POST_EDITOR_ACTIONS === 'true',
  LOG_ONE_APP_MESSAGES: process.env.LOG_ONE_APP_MESSAGES === 'true',
  CHAT_APP_DEF_ID: '14517e1a-3ff0-af98-408e-2bd6953c36a2',
  BLOG_APP_ID: '14bcded7-0066-7c35-14d7-466cb3f09103',
  PAID_PLANS_APP_ID: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  PAID_PLANS_SECTION_ID: 'membership_plan_picker_tpa'
};