import createAction from '../../common/services/create-action';
import classicPagintationUtils from '../services/init-classic-pagination';
import { getPaginationConfig } from '../constants/comments-pagination-config';
import { getDeepLinkParams } from '../services/get-deep-link-params';
import { getPostBySlug } from '../selectors/post-selectors';
import { EXPERIMENT_URL_CUSTOMIZATION, buildPageUrl } from '@wix/communities-forum-client-commons';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { buildCustomizedUrl } from '../services/build-customized-url';

export const SET_PAGER_STATE = 'SET_PAGER_STATE';
export const RESET_PAGER_STATE = 'RESET_PAGER_STATE';

export const setPagerState = (payload) => ({
  type: SET_PAGER_STATE,
  payload,
});
export const resetPagerState = createAction(RESET_PAGER_STATE);

export const syncPager =
  (paginationState, routeParams) =>
  (dispatch, getState, { wixCodeApi }) => {
    const state = getState();
    const post = getPostBySlug(state, routeParams?.postSlug);
    const resourcePaginationState = paginationState[post?._id];
    const getPageParam = () => routeParams?.page ?? '';
    const initialPaginationConfig = getPaginationConfig(post);

    const buildUrlWithPagination = (pageParam, isFullUrl) => {
      const state = getState();
      const path = buildPageUrl(routeParams?.categorySlug, routeParams?.postSlug, pageParam);
      const isUrlCustomizationEnabled = isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION);
      const sectionUrl = getForumSectionUrl(wixCodeApi);

      const pageUrl = wixCodeApi.location.baseUrl;
      const sectionPath = sectionUrl.replace(pageUrl, '');
      const destination = `${isFullUrl ? sectionUrl : sectionPath}${path}`;

      return isUrlCustomizationEnabled
        ? buildCustomizedUrl(state, path, isFullUrl) || destination
        : destination;
    };

    if (!resourcePaginationState || resourcePaginationState.type === 'NONE') {
      return;
    }

    const { commentToFocusId } = getDeepLinkParams(routeParams.deepLinkData);
    const isDeepLinkPage = !!commentToFocusId;

    classicPagintationUtils.syncPageParamToUrl({
      isDeepLinkPage,
      resourcePaginationState,
      initialPaginationConfig,
      getPageParam,
      setPageParam: (pageParam) => {
        wixCodeApi.location.to &&
          wixCodeApi.location.to(buildUrlWithPagination(pageParam), { disableScrollToTop: true });
      },
    });

    const commentsPaginationConfig = classicPagintationUtils.resolveCommentsPaginationConfig({
      initialPaginationConfig,
      getPageParam,
    });
    const pagerState = classicPagintationUtils.resolvePagerState({
      resourcePaginationState,
      initialPaginationConfig,
      buildUrl: (pageParam) => buildUrlWithPagination(pageParam, true),
    });

    dispatch(
      setPagerState({
        resourceId: post?._id,
        resourcePaginationState,
        commentsPaginationConfig,
        pagerState,
      }),
    );
  };
