import { createAction, Dispatch } from '@reduxjs/toolkit';
import { CommonRequest } from '../../app/types';
import { getCurrentUser } from '../store/current-user/current-user-selectors';

export const INCREMENT_POST_LIKE_COUNT_REQUEST = 'post/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_POST_LIKE_COUNT_SUCCESS = 'post/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_POST_LIKE_COUNT_FAILURE = 'post/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementPostLikeCountRequest = createAction(
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  (postId: string, currentUser: any) => ({ payload: { _id: postId, currentUser } }),
);

export const incrementPostLikeCountFailure = createAction(
  INCREMENT_POST_LIKE_COUNT_FAILURE,
  (postId: string, currentUser: any) => ({ payload: { _id: postId, currentUser } }),
);

export const incrementPostLikeCountSuccess = createAction(
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  (postId: string, currentUser: any, { count, isLiked }: IncrementPostLikeCountApiData) => ({
    payload: { _id: postId, currentUser, likeCount: count, isLiked },
  }),
);

interface IncrementPostLikeCountApiData {
  count: number;
  isLiked: boolean;
}

export function incrementPostLikeCount(postId: string) {
  return (dispatch: Dispatch, getState: any, { request }: { request: CommonRequest }) => {
    const currentUser = getCurrentUser(getState());

    dispatch(incrementPostLikeCountRequest(postId, currentUser));
    const promise = request.post<IncrementPostLikeCountApiData>(`/posts/${postId}/likes`, {});
    return promise
      .then(
        (data) => dispatch(incrementPostLikeCountSuccess(postId, currentUser, data)),
        () => dispatch(incrementPostLikeCountFailure(postId, currentUser)),
      )
      .then(() => promise);
  };
}
