import { once } from 'lodash';
import { bindActionCreators } from 'redux';
import { fetchSubcategories } from '../../common/actions/fetch-categories';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { setPostTypeFilter, setCategoryFilter } from '../actions/set-filter';
import { resetPostTypeFilter } from '../actions/reset-filter';
import { subscribeToCategory } from '../actions/subscribe-to-category';
import { fetchLocalStorage } from '../actions/fetch-local-storage';
import { setLocalStorageItem } from '../actions/set-local-storage-item';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import { pageOpened } from '../actions/page-opened';
import { modalActions } from '../../common/modals/framework/initialize-store';
import search, { clearSearchResults } from '../actions/search';
import { incrementPostLikeCount } from '../../common/actions/increment-post-like-count';
import { messageActions } from '../../common/messages/framework/initialize-store';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { setPostsPageSorting, setSearchPageSorting } from '../actions/set-sorting';
import { setIsLoaded } from '../actions/set-is-loaded';
import { setIsLoading } from '../actions/set-is-loading';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { unsubscribeFromPost } from '../actions/unsubscribe-from-post';
import { pinPost } from '../actions/pin-post';
import { unpinPost } from '../actions/unpin-post';
import { enablePostComments } from '../actions/enable-post-comments';
import { disablePostComments } from '../actions/disable-post-comments';
import { actionsOpened } from '../actions/actions-opened';
import { actionsClosed } from '../actions/actions-closed';
import { buttonClicked } from '../actions/button-clicked';
import { emitOpenCategory, emitCloseCategory } from '../actions/category-socket';
import { emitClosePost, emitOpenPost } from '../actions/post-socket';
import { createPostAndNavigateToItPromisified } from '../actions/create-post';
import { updatePostPromisified } from '../actions/update-post';
import { resetPagerState } from '../actions/comments-pagination-actions';
import { changePage } from '../actions/change-page';
import { goBack } from '../actions/go-back';
import { fastFormSubmitCallback } from '../../common/components/fast-form/store/fast-form-actions';
import { appLoaded } from '../actions/app-loaded';
import { setHasReportedAppLoaded } from '../../common/store/app-loaded/app-loaded-actions';
import { fetchBadges } from '../actions/fetch-badges';
import { fetchPaidPlans } from '../actions/fetch-paid-plans';
import { requestFileDownloadUrlPromisified } from '../../common/services/wix-media/media-api';
import { fileDownloadRequest } from '../actions/file-upload-bi-actions';
import {
  userEventsShowEmptyState,
  userEventsClickPublish,
  userEventsClickLike,
  userEventsClickProfileLink,
  userEventsClickCreatePost,
  userEventsClickCreatePostButton,
  userEventsSelectCategoryOnCreatePost,
  userEventsClickCreatePostButtonNoCategory,
  userEventsGuidelinesShown,
  userEventsPostingDisabledClose,
  userEventsSearchFocused,
  userEventsSearchCleared,
  userEventsSearchSuggestionsLoaded,
  userEventsSearchSubmitted,
} from '../actions/user-events';
import { requestLoginPromisified, requestLogin } from '../actions/request-login';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import {
  fetchMembersGroupsList,
  fetchMembersGroupsListPromisified,
} from '../actions/fetch-members-groups-list';
import { triggerOfflineMigration } from '../actions/offline-migration-actions';
import { fetchCategoriesGroups } from '../../common/actions/fetch-categories-groups';
import { navigateToPaidPlans } from '../actions/navigate-to-paid-plans';
import { fetchLikesDataPromisified, resetLikesData } from '../actions/who-liked-actions';
import { fetchSimilarPosts } from '../actions/fetch-similar-posts';
import { fetchExternalLinks } from '../actions/fetch-external-links';
import {
  onBeforePostCreateHook,
  onBeforePostUpdateHook,
  onFetchExternalLinksHook,
} from './forum-external-hooks';
import { subscribeToAllCategories } from '../actions/subscribe-to-all-categories';
import { unsubscribeFromAllCategories } from '../actions/unsubscribe-from-all-categories';
import { hideGuidelines, showGuidelines } from '../actions/guidelines';
import { startLoadingCategory } from '../actions/start-loading-category';
import { navigateToMainPage } from '../containers/header-navigation';
import { updateForumData, togglePosting } from '../actions/update-forum-data';
import { fetchSearchSuggestionsPromisified } from '../actions/fetch-search-suggestions';
import {
  markPostsAsRead,
  markPostAsRead,
  markPostAsUnread,
  markPostViewed,
} from '../containers/user-activity';
// @ts-expect-error
import { SANTA_MEMBERS_APP_ID, CHAT_APP_DEF_ID } from '../constants/applications';
import { FedopsLogger, Sentry, WixCodeApi } from '../types/platform-types';
import { Store } from '../types/store-types';
import { default as BiLocationTrackerConstructor } from '../bi-events/bi-location-tracker';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { decorateActionsWithLogger } from '../../common/services/monitoring';
import { InteractionController } from '../../common/interactions/interaction-controller';
import { interactionError } from '../../common/interactions/interactions.actions';
import { focusCommentBox } from '../actions/focus-comment-box';

export const createControllerActions = ({
  wixCodeApi,
  store,
  fedopsLogger,
  BiLocationTracker,
  sentry,
}: {
  wixCodeApi: WixCodeApi;
  store: Store;
  fedopsLogger: FedopsLogger;
  BiLocationTracker: typeof BiLocationTrackerConstructor;
  sentry: Sentry;
}) => {
  return {
    captureMessage: sentry ? sentry.captureMessage : () => {},
    interactionStarted: (interaction: string) =>
      InteractionController.interactionStarted(interaction, fedopsLogger),
    interactionEnded: (interaction: string) => {
      const hasEnded = InteractionController.interactionEnded(interaction, fedopsLogger);
      if (hasEnded === false) {
        store.dispatch(interactionError({ interaction }));
      }
    },
    startPrivateChat: (uid: string) =>
      wixCodeApi.site.getPublicAPI(CHAT_APP_DEF_ID).then((api) => api.startPrivateChat(uid)),
    navigateToSection: (section: string) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToSection(section)),
    navigateToMember: ({ memberId, memberSlug }: { memberId: string; memberSlug: string }) =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToMember({ memberId, memberSlug })),
    logout: () => wixCodeApi.user.logout(),
    navigateToRoot: () => {
      wixCodeApi.location.to?.('/');
    },
    navigateToProfile: ({ memberId, memberSlug }: { memberId: string; memberSlug: string }) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
            memberSlug: user.slug,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    renderSEOTags: (data: Parameters<WixCodeApi['seo']['renderSEOTags']>[0]) =>
      wixCodeApi.seo.renderSEOTags(data),
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          appLoaded: once(appLoaded),
          showJoinCommunityPopup,
          search,
          incrementPostLikeCount,
          pageOpened,
          navigateWithinForum,
          navigateToPaidPlans,
          fetchPostPageData,
          setIsLoaded,
          setIsLoading,
          fetchCategoryPosts,
          fetchSubcategories,
          subscribeToCategory,
          subscribeToPost,
          unsubscribeFromPost,
          subscribeToAllCategories,
          unsubscribeFromAllCategories,
          pinPost,
          unpinPost,
          enablePostComments,
          disablePostComments,
          actionsOpened,
          actionsClosed,
          buttonClicked,
          changePage,
          setPostsPageSorting,
          goBack,
          emitOpenCategory,
          emitCloseCategory,
          emitClosePost,
          emitOpenPost,
          fastFormSubmitCallback,
          setSearchPageSorting,
          setHasReportedAppLoaded,
          fileDownloadRequest,
          userEventsShowEmptyState,
          userEventsClickPublish,
          userEventsClickLike,
          userEventsClickProfileLink,
          userEventsClickCreatePost,
          userEventsClickCreatePostButton,
          userEventsSelectCategoryOnCreatePost,
          userEventsClickCreatePostButtonNoCategory,
          userEventsGuidelinesShown,
          userEventsPostingDisabledClose,
          userEventsSearchFocused,
          userEventsSearchCleared,
          userEventsSearchSuggestionsLoaded,
          userEventsSearchSubmitted,
          requestLogin,
          setSavedAction,
          ...modalActions,
          ...messageActions,
          fetchMembersGroupsList,
          fetchBadges,
          fetchPaidPlans,
          setPostTypeFilter,
          setCategoryFilter,
          resetPostTypeFilter,
          clearSearchResults,
          triggerOfflineMigration,
          fetchCategoriesGroups,
          fetchSimilarPosts,
          resetLikesData,
          markPostsAsRead,
          fetchExternalLinks: fetchExternalLinks(onFetchExternalLinksHook),
          fetchLocalStorage,
          setLocalStorageItem,
          hideGuidelines,
          showGuidelines,
          startLoadingCategory,
          navigateToMainPage,
          updateForumData,
          togglePosting,
          markPostAsRead,
          markPostAsUnread,
          resetPagerState,
          focusCommentBox,
          markPostViewed,
        },
        store.dispatch,
      ),
      sentry,
    ),
    setBiLocation: BiLocationTracker.set,
  };
};

export const createControllerPromisifiedActions = ({
  store,
  sentry,
}: {
  store: Store;
  sentry: Sentry;
}) => {
  return {
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          createPostAndNavigateToItPromisified:
            createPostAndNavigateToItPromisified(onBeforePostCreateHook),
          updatePostPromisified: updatePostPromisified(onBeforePostUpdateHook),
          requestFileDownloadUrlPromisified,
          requestLoginPromisified,
          fetchMembersGroupsListPromisified,
          fetchLikesDataPromisified,
          fetchSearchSuggestionsPromisified,
        },
        store.dispatch,
      ),
      sentry,
    ),
  };
};
