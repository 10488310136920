import { isExperimentEnabled } from '../selectors/experiments-selectors';
import {
  EXPERIMENT_ENABLE_POST_LIST_FEED_VIEW,
  LAYOUT_TYPE_FEED,
} from '@wix/communities-forum-client-commons';
import { getLayoutType } from '../selectors/layout-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const COMMENTS_PER_PAGE_DESKTOP = 20;
export const COMMENTS_PER_PAGE_MOBILE = 20;
export const POSTS_PER_PAGE = 30;
export const POSTS_PER_FEED_PAGE = 5;
export const SEARCH_RESULTS_PER_PAGE_MOBILE = 10;
export const SEARCH_RESULTS_PER_PAGE_DESKTOP = 20;

export const getCommentsPerPage = (isMobile) =>
  isMobile ? COMMENTS_PER_PAGE_MOBILE : COMMENTS_PER_PAGE_DESKTOP;

export const getSearchResultsPerPage = (isMobile) =>
  isMobile ? SEARCH_RESULTS_PER_PAGE_MOBILE : SEARCH_RESULTS_PER_PAGE_DESKTOP;

export const getPostPageSize = (state, style) => {
  const isFeedLayoutEnabled = isExperimentEnabled(state, EXPERIMENT_ENABLE_POST_LIST_FEED_VIEW);
  const layoutType = getLayoutType(state, style);
  const isMobile = getIsMobile(state);
  return isFeedLayoutEnabled && layoutType === LAYOUT_TYPE_FEED && !isMobile
    ? POSTS_PER_FEED_PAGE
    : POSTS_PER_PAGE;
};
