import { pick } from 'lodash';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import sanitizeContent from '../services/sanitize-content';
import {
  extractHashtagsFromContent,
  extractMentionsFromContent,
} from '@wix/communities-forum-client-commons';

import { POST_UPDATE } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getRouteParams } from '../../common/router/router-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';

interface Post {
  _id: string;
  categoryId: string;
  content: any;
  title: string;
}

export interface UpdatePostSuccess extends Post {
  postId: string;
  postType: string;
}

export const UPDATE_POST_REQUEST = 'post/UPDATE_REQUEST';
export const UPDATE_POST_SUCCESS = 'post/UPDATE_SUCCESS';
export const UPDATE_POST_FAILURE = 'post/UPDATE_FAILURE';

export const updatePostRequest = createAction(UPDATE_POST_REQUEST);
export const updatePostSuccess = createAction<UpdatePostSuccess>(UPDATE_POST_SUCCESS);
export const updatePostFailure = createAction(UPDATE_POST_FAILURE, (meta) => ({
  payload: undefined,
  meta,
}));

const PROPS = ['title', 'content'];

export function updatePost(post: Post) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(updatePostRequest());

    const updatedPostSanitized = sanitizeContent({ content: '', ...pick<Post>(post, PROPS) });
    const promise = request.patch<UpdatePostSuccess>(`/posts/${post._id}`, {
      ...updatedPostSanitized,
      hashtags: extractHashtagsFromContent(updatedPostSanitized.content),
      mentions: extractMentionsFromContent(updatedPostSanitized.content),
    });

    return promise
      .then(
        (updatedPost) => {
          dispatch(updatePostSuccess(updatedPost));
          dispatch(showMessage(POST_UPDATE));
          const params = getRouteParams(getState());
          dispatch(closeModal({ type: MODAL_TYPE_EDIT_POST, resolve: false }));
          dispatch(navigateWithinForum(`/${params.categorySlug}/${params.postSlug}`));
        },
        (response) => dispatch(updatePostFailure(response)),
      )
      .then(() => promise);
  };
}

export const updatePostPromisified = (onBeforePostUpdateHook: any) =>
  createPromisifiedAction(
    (post: Post) => {
      if (onBeforePostUpdateHook.hasHook()) {
        return (dispatch: AppDispatch) =>
          onBeforePostUpdateHook
            .exec(post)
            .then(
              (r: any) => dispatch(updatePost(isExternalHookResolve(r) ? r.payload || post : post)),
              createExternalHookRejectHandler(dispatch),
            );
      } else {
        return updatePost(post);
      }
    },
    () => null,
    (response) => response.status,
  );
